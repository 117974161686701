import React from 'react'
import Papa from 'papaparse'
import './App.css';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DataModel } from './model';

function App() {

  const [kamera1, setKamera1] = React.useState<DataModel[]>([])
  const [kamera2, setKamera2] = React.useState<DataModel[]>([])
  React.useEffect(() => {
    async function getData1() {
      const response = await fetch('../assets/czestochowa1.csv')
      const reader = response.body?.getReader()
      const result = await reader?.read() // raw array
      const decoder = new TextDecoder('utf-8')
      const csv = decoder.decode(result?.value) // the csv text
      const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
      const data = (results.data as DataModel[]).map(x => {
        let dmYInt = 0-Number(x.dyMm);
        x.dyMm = dmYInt.toString();
        return x;
      })
      setKamera1(data)
    }
    async function getData2() {
      const response = await fetch('../assets/czestochowa2.csv')
      const reader = response.body?.getReader()
      const result = await reader?.read() // raw array
      const decoder = new TextDecoder('utf-8')
      const csv = decoder.decode(result?.value) // the csv text
      const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
      const data = (results.data as DataModel[]).map(x => {
        let dmYInt = 0-Number(x.dyMm);
        x.dyMm = dmYInt.toString();
        return x;
      })
      setKamera2(data)
    }
    getData1()
    getData2()
  }, []) // [] means just do this once, after initial render
  return (
    <div className="app">
      <div>
      <h1>Kamera 1 przemieszczenie pionowe</h1>
        <ResponsiveContainer width="95%" height={300}>
          <LineChart
            data={kamera1}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="DateTimeOriginal" />
            <YAxis type="number" domain={['auto', 'auto']} />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="dyMm" stroke="#000dff" yAxisId={0} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
      <h1>Kamera 1 przemieszczenie poziome</h1>
      <h4>Wartości dodatnie obiekt przesuwa się w prawo, wartości ujemne obiekt przesuwa się w lewo</h4>

        <ResponsiveContainer width="95%" height={300}>
          <LineChart
            data={kamera1}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="DateTimeOriginal" />
            <YAxis type="number" domain={['auto', 'auto']} />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="dxMm" stroke="#ff7300" yAxisId={0} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
        <img src='/assets/czestochowa1/czestochowa1_00_20221025195336.jpg' width='700px'/>
      </div>
      <div>
      <h1>Kamera 2 przemieszczenie pionowe</h1>
        <ResponsiveContainer width="95%" height={300}>
          <LineChart
            data={kamera2}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="DateTimeOriginal" />
            <YAxis type="number" domain={['auto', 'auto']} />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="dyMm" stroke="#000dff" yAxisId={0} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
      <h1>Kamera 2 przemieszczenie poziome</h1>
      <h4>Wartości dodatnie obiekt przesuwa się w prawo, wartości ujemne obiekt przesuwa się w lewo</h4>

        <ResponsiveContainer width="95%" height={300}>
          <LineChart
            data={kamera2}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="DateTimeOriginal" />
            <YAxis type="number" domain={['auto', 'auto']} />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="dxMm" stroke="#ff7300" yAxisId={0} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
        <img src='/assets/czestochowa2/czestochowa2_00_20221025200112.jpg' width='700px'/>
      </div>
    </div>
  )
}

export default App;
